import {useTranslations, type HydratedT} from '@/components/shared/Page/I18N';

export {useTranslations};

interface SharedTranslations {
  t: HydratedT;
}

export function useSharedTranslations(ns: string): SharedTranslations {
  const {t: translate} = useTranslations();
  const t: HydratedT = (key, options) => translate(`${ns}:${key}`, options);
  return {t};
}
